﻿/**
 * Main Navigation
 */
(function () {
    const mainNavigation = new nav({
        toggle: '.js-menu_toggle',
        searchToggle: '.js-menu_tool-search',
        el: '.js-main_menu',
        breakpoint: 1024
    });
})();
/**
 * Main Navigation Constructor
 * @param {string, selector} el Selector for the parent navigation element
 * @param {string, selector} toggle Selector for the nav toggle element
 * @param {int}              breakpoint The viewport pixel width where we transision from mobile nav to desktop.
 *                           Equal to the last pixel where the mbile view is used
 */
function nav(config) {
    const self = this;
    self.el = document.querySelector(config.el);
    self.toggle = document.querySelector(config.toggle);
    self.searchToggle = document.querySelector(config.searchToggle);
    //self.searchEl = document.querySelector('.js-header_search');
    self.breakpoint = config.breakpoint;
    self.header = document.querySelector('.js-header');
    self.isMobile = false;
    self.isActive = false; // Is nav open or closed?
    self.isSearchOpen = false; // Is the search menu open or closed?
    self.mainLinks = self.el.getElementsByClassName('js-menu__section__link');
    self.subnavs = Array.from(self.el.querySelectorAll('.js-menu__sublevel'));
    self.navClose = document.querySelector('.js-menu__close'); // The X used for closing the non-mobile nav

    //var animSubNavIn = new TimelineLite();
    const indicator = document.querySelector('.c-menu__indicator');
    // Initial setup
    self.init = () => {
        // Set the flag for mobile nav
        self.isMobile = checkMobile();

        //console.log(self.isMobile);
        // Handle closing nav on mouseout
        if (!self.isMobile) {
            self.el.closest(".js-header").addEventListener("mouseout", (e) => {
                if (e.target === document.querySelector(".js-menu__sublevel.is-active")) {
                    self.close();
                    self.isActive = false;
                  
                    const activeNavs = self.el.querySelectorAll('.c-menu__link_is-active');

                    if (activeNavs.length > 0) {
                        //console.log("toggle");
                     
                        activeNavs.forEach(x => x.classList.toggle("c-menu__link_is-active"))
                    }
                }
            });
       
        }

        // Handle nav links with subnav
        const linksSubnav = Array.from(document.querySelectorAll('.js-menu__link--has_subnav')); // Links with subnav


        linksSubnav.map((link) => {
            // attach event listeners
            //console.log(link.parentNode)
            const eventArr = self.isMobile ? ['click'] : ['mouseover', 'click'];
            if (!self.isMobile) {
                eventArr.forEach((x) => {
                    link.addEventListener(x, (e) => {
                        if (!self.isActive || !link.classList.contains("c-menu__link_is-active")) {
                            e.preventDefault();
                            const subnav = link.parentNode.querySelector('.js-menu__sublevel');
                            //console.log(subnav)

                            // On mobile, clicking the link shows subnav
                            if (self.isMobile) {
                                openSubnav(subnav);
                                self.setOpen(); // make sure we set open states
                            } else {
                                const activeNavs = self.el.querySelectorAll('.c-menu__link_is-active');
                              
                                if (activeNavs.length > 0) {
                                    activeNavs.forEach(x => x.classList.toggle("c-menu__link_is-active"))
                                }
                                //console.log('active nav', link);
                                var currentIndex = link.getAttribute('data-index');
                                if (currentIndex == 1) {
                                    indicator.style.marginLeft = '0%';
                                    indicator.style.opacity = 1;
                                }
                                if (currentIndex == 2) {
                                    indicator.style.marginLeft = '20%';
                                    indicator.style.opacity = 1;
                                }
                                if (currentIndex == 3) {
                                    indicator.style.marginLeft = '40%';
                                    indicator.style.opacity = 1;
                                }
                                if (currentIndex == 4) {
                                    indicator.style.marginLeft = '60%';
                                    indicator.style.opacity = 1;
                                }
                                if (currentIndex == 5) {
                                    indicator.style.marginLeft = '80%';
                                    indicator.style.opacity = 1;
                                }


                                link.classList.toggle("c-menu__link_is-active")
                                //console.log(subnav.getAttribute('aria-expanded'), "**");
                                // On desktop, clicking the link shows subnav or hides it if it's already visible
                                if (subnav.getAttribute('aria-expanded') != 'true') {
              
                                    // close all open subnavs, in case we're switching setcions
                                    self.subnavs.map((subnav) => {
                                        closeSubnav(subnav);
                                    });

                                    openSubnav(subnav);
                                    self.setOpen(); // make sure we set open states
                                    self.isActive = true;
                                } else {
                                    self.isActive = false;
                                    closeSubnav(subnav);
                                    self.close();
                                    indicator.style.opacity = 0;
                                }
                                if (self.isSearchOpen) {
                                    self.isActive = false;
                                    closeSearch();
                                }
                            }
                        }
                    })
                });
            }

            /*link.addEventListener("mouseover", (e) => {
                console.log(e.target);
            });*/
        });
    };

    /**
     * Handle click outside nav to close
     */
    document.addEventListener('mouseover', (e) => {
        if (!e.target.closest('.js-header') && self.isActive) {
            //console.log(e);
            // Hide any open navs
            e.preventDefault;
            self.close();
            //console.log("moused over");
            const activeNavs = self.el.querySelectorAll('.c-menu__link_is-active');
            if (activeNavs.length > 0) {
                activeNavs.forEach(x => x.classList.toggle("c-menu__link_is-active"))
            }

            // If search is open, close it
            //closeSearch();
        }
    });

    /**
     * Open the nav (doesn't include search)
     */
    self.setOpen = () => {
        // Add the nav-active class to the head
        self.header.classList.add('nav-active');
        indicator.style.opacity = 1;

        // Add the active class to the toggle
        //self.toggle.classList.add('is-active');
        
        // Show the nav
        self.el.classList.add('is-active');

        // Show the nav close icon
        //self.navClose.classList.add('is-visible');

        self.isActive = true;
    };

    /**
     * Close the nav (doesn't include search)
     */
    self.close = function () {
        // Remove the nav-active class from the header
        self.header.classList.remove('nav-active');
        // Add the active class to the toggle
        //self.toggle.classList.remove('is-active');
        // Hide the nav
        self.el.classList.remove('is-active');

        // Hide the nav close icon
        // self.navClose.classList.remove('is-visible');

        // Reset any explicit styles (nav height etc)
        self.el.removeAttribute('style');

        indicator.style.opacity = 0;

        // Remove any active classes on children and set any expanded roles to false
        Array.from(self.el.getElementsByTagName('*')).map((el) => {
            el.classList.remove('is-active');

            if (el.getAttribute('aria-expanded')) {
                el.setAttribute('aria-expanded', 'false');
            }
        });

        self.isActive = false;
    };

    // Execute init on instantiation
    self.init();

    function checkMobile() {
        if (window.innerWidth < config.breakpoint) {
            return true;
        } else {
            return false;
        }
    }

    function openSearch() {
        // Reset an open navigation
        Array.from(self.el.getElementsByTagName('*')).map((el) => {
            el.classList.remove('is-active');
        });

        // If there's subnav open, close it


        // Open the search
        self.searchEl.classList.add('is-active');
        // Add the nav-active class to the head
        self.header.classList.add('nav-active');
        self.isSearchOpen = true;

        setTimeout(() => {
            document.querySelector('.js-header_search__input').focus();
        }, 500);
    }

    function closeSearch() {
        self.searchToggle.classList.remove('is-open');
        self.searchEl.classList.remove('is-active');
        self.isSearchOpen = false;
    }

    function closeSubnav(subnav) {
        subnav.classList.remove('is-active');
        subnav.setAttribute('aria-expanded', 'false');
        indicator.style.opacity = 0;
        //animSubNavIn.clear();
    }

    function openSubnav(subnav) {
        subnav.classList.add('is-active');
        subnav.setAttribute('aria-expanded', 'true');

        // get links for this subnav
        const items = subnav.querySelectorAll('.js-menu__sublevel_item');
        // animate in the links
        //animSubNavIn.staggerFromTo(items, 0.125, {
            //x: 80,
            //opacity: 0
        //}, {
            //x: 0,
            //opacity: 1
        //}, 0.05).play();
    }
}
